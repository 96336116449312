import React, { useEffect,useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import { clearAll } from '../../actions/form'
import axios from 'axios';
import Modal from '@material-ui/core/Modal';
import Amplify, { Auth, Storage } from 'aws-amplify';
import API from '../../helpers/api'
import { loadTree } from '../../actions/form';

Amplify.configure({
    Auth: {
        identityPoolId: 'ap-southeast-1:306b09dd-a9be-40b4-b8eb-b14ffcb5d48b', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'ap-southeast-1', // REQUIRED - Amazon Cognito Region
    },
    Storage: {
        AWSS3: {
            bucket: `${process.env.REACT_APP_AWS_URL}`, //REQUIRED -  Amazon S3 bucket name
            region: 'ap-southeast-1', //OPTIONAL -  Amazon service region
            level: "public",
        }
    }
});
let category_marker = {
    'fertilizer': '',
    'pest': '',
    'pesticide': '',
    'disease': '',
    'fungicide': '',
}

function formatDate(date) {
    let year = date.getFullYear(),
        month = date.getMonth() + 1, // months are zero indexed
        day = date.getDate(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds(),
        hourFormatted = hour,
        minuteFormatted = minute < 10 ? "0" + minute : minute,
        morning = hour < 12 ? "am" : "pm";

        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        if (hourFormatted < 10) {
            hourFormatted = "0" + hourFormatted;
        }
        if (second < 10) {
            second = "0" + second;
        }
    return year + "-" + month + "-" + day + "T" + hourFormatted + ":" + minuteFormatted + ":" + second;
}
const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

let uuid=''
const MenuLayout = ({clearAll, user, form, loadTree})  => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [modalStatus, setModalStatus] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [serial, setSerial] = useState('')
    let query = new URLSearchParams(useLocation().search);

    useEffect( async () => {
        uuid = query.get("uuid")
        console.log('MenuLayout', uuid)
        if (uuid == 'undefined' || uuid == null || uuid == '') {
            return navigate('/staff/scan')
        }
        if (!form.tree.uuid) {
            const res = await loadTree(uuid);
            if (res == false) {
                // invalid uuid
                return navigate('/staff/scan')
            }
        }
    }, [])

    // check state of form
    useEffect(() => {
        for (let i in form) {
            let lastIndex = `${i}`.lastIndexOf("s");
            let category = `${i}`.substring(0, lastIndex);
            if (i !== 'tree' && i != 'options') {
                // FOR PEST AND DISEASE, DONT HAVE TO COMPULSORY FILL IN TYPE. IMAGES WILL DO.
                if (i == 'pests' || i =='diseases') {
                    if (form[i][`${category}_images`].length > 0 || form[i][category].length > 0) {
                        category_marker[category] = '✅'
                    } else {
                        category_marker[category] = ''
                    }
                } else {
                    if (form[i][category] !== '') {
                        category_marker[category] = '✅'
                    } else {
                        category_marker[category] = ''
                    }
                }
            }
        }
    }, [form])

    useEffect( async () => {
        setSerial(form.tree.serial)
    }, [form])

    const handleNav = (nav) => {
        console.log('handleNav')
        return navigate('/staff/'+nav);
        
    }
    const confirmation = () => {
        if (window.confirm('Are you sure you want to clear all saved inputs?')) {
            clearAll()
        }
    }
    const closeModal = () => {
        setModalStatus(false);
    };
    const openModal = () => {
        setModalStatus(true);
    };

    const submitForm = async() => {
        //check if submitting empty form
        let count=0
        Object.keys(form).map(function(key) {
            let lastIndex = `${key}`.lastIndexOf("s");
            let category = `${key}`.substring(0, lastIndex);
            if (key!=='tree' && key!='options') {

                // FOR PEST AND DISEASE, DONT HAVE TO COMPULSORY FILL IN TYPE. IMAGES WILL DO.
                if (key == 'pests' || key == 'diseases' ) {
                    if (form[key][`${category}_images`].length > 0 || form[key][category].length > 0) {
                        count ++
                    }
                } else {
                    if (form[key][`${category}`] !== '') {
                        count ++
                    }
                }
                
            }
        });
        if (count == 0) return alert('Please filled in a form before submitting.') 

        // let username = user ? user.username : ''
        let datetime = new Date()
        let image_detail = []
        let data = []
        
        for (let i in form ) {
            let lastIndex = `${i}_amount`.lastIndexOf("s");
            let category = `${i}_amount`.substring(0, lastIndex);
            if ((form[i][`${category}`] !== '') && (i != 'tree' && i != 'options')) {
                if (form[i][`${category}_images`] && form[i][`${category}_images`].length>0) {
                    // add images_name as an array into data
                    let counter = 0;
                    let datetime = formatDate(new Date())
                    let image_arr = []
                    form[i][`${category}_images`].map(val => {
                        let image_name = datetime + '_'+ counter + '.jpg'
                        image_arr.push(image_name)
                        image_detail.push({
                            'image_name': image_name,
                            'image_file': val,
                            'category': category
                        })
                        counter++
                    })

                    data.push({
                        // ...form[i],
                        "uuid": uuid,
                        'datetime': datetime,
                        'username': 'bekzat',
                        'category': category,
                        'type': form[i][`${category}`] ,
                        "amount": form[i][`${category}_amount`],
                        "images": image_arr,
                    })
                    counter++
                } else {
                    data.push(
                        {
                            "uuid": uuid,
                            'datetime': datetime,
                            'username': 'bekzat',
                            'category': category,
                            'type': form[i][`${category}`] ,
                            "amount": form[i][`${category}_amount`],
                        }
                    )
                }
            }
        }
        console.log(data)
        

        if (localStorage.getItem('access')) {
            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('access')}`,
                }
            };

            setSubmitting(true)
            API.post(`${process.env.REACT_APP_API_URL}/tree/saveEventList/`, {
                "event": data
            }, config)
            .then(function (response) {
                delete axios.defaults.headers.common["Authorization"];
                // Submit data with images only.
                image_detail.map(  img => {
                    // <serial>/<category>/timestamp_XX.jpg
                    Storage.put(serial +'/'+ img['category'] + '/' + img['image_name'], img['image_file'], {
                        contentType: 'image/jpg',
                        level: 'public',
                        type: 'application/json'
                    })
                    .then ( async result => console.log(result))
                    .catch(err => console.log(err));
                })
                
                setSubmitting(false)
                setModalStatus(false)
                alert('Succesfully Created!')
            })
            .catch(function (error) {
                console.log(error);
                setSubmitting(false)
                alert('Something wrong!')
            });

        } else {
            console.log('Unauthorized. Please log in.')
        }
    }

    const SubmitContent = () => {
        let return_html=[]
        Object.keys(form).map(function(key, val) {
            if (key !='tree' && key != 'options') {
                let lastIndex = `${key}`.lastIndexOf("s");
                let category = `${key}`.substring(0, lastIndex);

                // FOR PEST AND DISEASE, DONT HAVE TO COMPULSORY FILL IN TYPE. IMAGES WILL DO.
                if (key == 'pests' || key == 'diseases' ) {
                    if (form[key][`${category}_images`].length > 0 || form[key][category].length > 0) {
                        return_html.push(<div key={key}>✅ &nbsp;  {category}</div>)
                    }
                } else {
                    if (form[key][`${category}`] !== '') {
                        return_html.push(<div key={key}>✅ &nbsp;  {category}</div>)
                    }
                }
            }
        });
        return return_html
    }

    const backToScan = () => {
        clearAll()
        return navigate('/staff/scan')
    }
    return (
        <div className={classes.root} >
            <h3 style={{padding: '0 23px'}}>Categories - {(serial == null || serial == '') ? (<CircularProgress />) : (serial)}</h3>
            
            <Grid container spacing={3}>
                <Grid item xs={6} sm={6} className={classes.row}>
                    <Button variant="contained" color="primary" onClick={() => handleNav('fertilizers')}>
                    Fertilizers {category_marker['fertilizer']}
                    </Button>
                </Grid>
                <Grid item xs={6} sm={6} className={classes.row} onClick={() => handleNav('pests')}>
                    <Button variant="contained" color="primary">
                        Pest {category_marker['pest']}
                    </Button>
                </Grid>

                <Grid item xs={6} sm={6} className={classes.row} onClick={() => handleNav('fungicides')}>
                    <Button variant="contained" color="primary">
                        Fungicide {category_marker['fungicide']}
                    </Button>
                </Grid>

                <Grid item xs={6} sm={6} className={classes.row} onClick={() => handleNav('diseases')}>
                    
                    <Button variant="contained" color="primary">
                        Disease {category_marker['disease']}
                        </Button>
                </Grid>

                <Grid item xs={6} sm={6} className={classes.row} onClick={() => handleNav('pesticides')}>
                    <Button variant="contained" color="primary">
                        Pesticide {category_marker['pesticide']}
                    </Button>
                </Grid>

                <Grid item xs={6} sm={6} className={classes.row}>
                    <Button variant="contained" color="primary">
                        Fruits
                    </Button>
                </Grid>

                <Grid container className={classes.submitRow}>
                     <Grid item sm={6} xs={12}>
                        <Button 
                            variant="outlined"
                            color="primary"
                            onClick={backToScan}
                            style={{width: '70%'}}
                            endIcon={<Icon>camera</Icon>}>
                            Scan
                        </Button>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <Button  
                            variant="outlined"
                            color="secondary"
                            onClick={confirmation}
                            endIcon={<DeleteIcon />}>
                            Clear
                        </Button>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <Button 
                            variant="contained"
                            color="primary"
                            onClick={openModal}
                            style={{backgroundColor: 'red !important'}}
                            endIcon={<Icon>check</Icon>}>
                            Confirm
                        </Button>
                    </Grid>
                </Grid>

            </Grid>


            <Modal
                open={modalStatus}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                >
                    <div  className={classes.modalContainer}>
                        <h4>Submitted forms:</h4>
                        <SubmitContent/>

                        <Box display="flex" flexDirection="row-reverse" >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={submitForm}
                                disabled={submitting}
                                endIcon={<Icon>send</Icon>}>
                                {submitting &&(
                                <CircularProgress />
                                )}Send
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={closeModal}
                                disabled={submitting}
                                style={{ margin: '0 20px'}}>
                                Close
                            </Button>
                        </Box>
                    </div>
            </Modal>
        </div>
    )
}
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px 60px'
    },
    row : {
        padding: '20px',
        textAlign: 'center',
        
        '& Button': {
            margin: theme.spacing(2),
            width: '85%',
        },
    },
    submitRow: {
        padding: '20px',
        textAlign: 'center',
        '& Button': {
            marginTop: '20px' ,
        },
    },
    modalContainer: {
        width: '80%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        margin: 'auto',
        marginTop: '200px',
    },
}));  
const mapStateToProps = state => ({
    user: state.auth.user,
    form: state.form,
});
export default connect(mapStateToProps , {clearAll, loadTree})(MenuLayout);

{/* <Button display="flex" 
        variant="contained"
        color="primary"
        endIcon={<Icon>send</Icon>}>
        Send
    </Button> */}