import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { connect } from 'react-redux';
import Amplify, { Auth, Storage } from 'aws-amplify';
import NodeViewModal from '../Timeline/components/NodeViewModal';
import NodeAddEditModal from '../Timeline/components/NodeAddEditModal';
import {formatDateTime, formatDate} from '../../helpers/helpers'
import { TreeNodeTable } from './TreeNodeTable/TreeNodeTable';
import { Modal,Button, IconButton} from '@material-ui/core';
import {PhotoCamera, Close, FreeBreakfastOutlined} from '@material-ui/icons';
import API from '../../helpers/api'

am4core.useTheme(am4themes_animated);
Amplify.configure({
    Auth: {
        identityPoolId: 'ap-southeast-1:306b09dd-a9be-40b4-b8eb-b14ffcb5d48b', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'ap-southeast-1', // REQUIRED - Amazon Cognito Region
    },
    Storage: {
        AWSS3: {
            bucket: `${process.env.REACT_APP_AWS_URL}`, //REQUIRED -  Amazon S3 bucket name
            region: 'ap-southeast-1', //OPTIONAL -  Amazon service region
        }
    }
});


const defaultData = {
    images: []
}

const config = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token 535261045567516f2108c7b8029b3d96f95ceddc`,
    }
};
const Timeline = ({tree, setRefresh, tableData}) => {
    const [chartData, setChartData] = useState(tableData)
    const [loading, setLoading] = useState(false)
    // let tree_id = '1192d481-b198-4df8-9962-57ee97fbc10d';
    let tree_id = tree[0].uuid;
    let tree_serial = tree[0].serial;
    const [tableModal, setTableModal] = useState(false)
    const [viewModal, setViewModal] = useState(false)
    const [addEditModal, setAddEditModal] = useState(false)
    const [modalStatus, setModalStatus] = useState(false)
    const [selectedItem, setSelectedItem] = useState(defaultData)
    const [tableDataView, setTableDataView] = useState([])
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        if (tableData !== undefined && tableData.length > 0) {
            let dates_exist = [];
            let temp_data = [];
            tableData.filter(item => {
                if (dates_exist.includes(formatDate(item.datetime)) ) {
                    for (let i of temp_data) {
                        if (i.date == formatDate(item.datetime)) {
                            i.count = i.count + 1;
                            i.events = [
                                ...i.events,
                                item
                            ]
                        }
                    }
                    
                } else {
                    temp_data.push({
                        'date': formatDate(item.datetime),
                        'count': 1,
                        'events': [item],
                        'category': 'timeline'
                    })
                    dates_exist.push(formatDate(item.datetime))
                }  
            })
            setChartData(temp_data)
        } else {
            setChartData([])
        }
    }, [tableData])


    useEffect(() => {
        createChart()
        return () => {
            am4core.options.autoDispose = true;
        };
    }, [chartData]);

    const searchImage = (category, datetime) => {
        let format_datetime = formatDateTime(new Date(datetime).toString())
        let image_arr = [];

        setSubmitting(true)
        API.get(`${process.env.REACT_APP_API_URL}/tree/downloadEventImage/${tree_serial}/${category}/${format_datetime}`)
        .then(res => {
            setSubmitting(false)
            if (res.data.url.length > 0) {
                for (let i in res.data.url) {
                    image_arr.push(res.data.url[i])
                }
                setSelectedItem( prev => ({
                    ...prev,
                    'images': image_arr
                }))
            } else {
                setSelectedItem( prev => ({
                    ...prev,
                    'images': []
                }))
            }
        }).catch(err => {
            setSubmitting(false)
            console.log(err)
        })

        // delete axios.defaults.headers.common["Authorization"];
        // Storage.list(tree_serial + '/' + category) 
        // .then(result => {
        //     if (result.length > 0) {
        //         result.map(async val=> {
        //             let possibleFolder = val.key.match(datetime)
        //             if (possibleFolder) {
        //                 let file_name = possibleFolder.input
        //                 let signedURL = await Storage.get(file_name)
        //                 image_arr.push(signedURL)
        //             }
        //             setSelectedItem( prev => ({
        //                 ...prev,
        //                 'images': image_arr
        //             }))
        //         })
        //     } else {
        //         setSelectedItem( prev => ({
        //             ...prev,
        //             'images': []
        //         }))
        //     }
        // })
    }

    const openImage = (url) => {
        return window.open(url, '_blank', 'noopener,noreferrer')
    }

    const createChart =()=> {
        let chart = am4core.create("chartdiv", am4plugins_timeline.SerpentineChart);
        chart.curveContainer.padding(50, 20, 50, 20);
        chart.levelCount = 4;
        chart.yAxisRadius = am4core.percent(25);
        chart.yAxisInnerRadius = am4core.percent(-25);
        chart.maskBullets = false;


        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm";
        chart.dateFormatter.dateFormat = "yyyy-MM-dd HH:mm";
        chart.data = chartData;


        let colorSet = new am4core.ColorSet();
        colorSet.step = 3;
        chart.data.map( val => {
            val['color'] = colorSet.next()
            val['datetimeStart'] = val.date + 'T00:00:00Z'
            val['datetimeEnd'] = val.date + 'T00:00:00Z'
        })

        chart.fontSize = 11;

        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category" ;
        // categoryAxis.fontSize = '12px'
        // categoryAxis.renderer.grid.template.disabled = true;
        // categoryAxis.renderer.labels.template.paddingRight = 25;
        // categoryAxis.renderer.minGridDistance = -10;
        // categoryAxis.renderer.innerRadius = -60;
        // categoryAxis.renderer.radius = 60;
        // categoryAxis.renderer.labels.template.propertyFields.fill = 'color'

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 70;
        dateAxis.baseInterval = { count: 24, timeUnit: "hour" };
        dateAxis.renderer.tooltipLocation = 0;
        dateAxis.startLocation = -0.5;
        dateAxis.renderer.line.strokeDasharray = "1,4";
        dateAxis.renderer.line.strokeOpacity = 0.6;
        dateAxis.tooltip.background.fillOpacity = 0.2;
        dateAxis.tooltip.background.cornerRadius = 5;
        dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
        dateAxis.tooltip.label.paddingTop = 7;

        // Time Range
        let start_time = formatDate(new Date().setDate(new Date().getDate() + 1))
        let endTime = formatDate(new Date(new Date().setMonth(new Date().getMonth()-3)));
        dateAxis.min = new Date(endTime).getTime();
        dateAxis.max = new Date(start_time).getTime();

        let labelTemplate = dateAxis.renderer.labels.template;
        labelTemplate.verticalCenter = "middle";
        labelTemplate.fillOpacity = 0.8;
        labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor("background");
        labelTemplate.background.fillOpacity = 1;
        labelTemplate.padding(7, 7, 7, 7);

        let series = chart.series.push(new am4plugins_timeline.CurveColumnSeries());
        series.columns.template.height = am4core.percent(20);
        series.columns.template.tooltipText = "{task}: [bold]{openDateX}[/] - [bold]{dateX}[/]";

        series.dataFields.openDateX = "datetimeStart";
        series.dataFields.dateX = "datetimeEnd";
        series.dataFields.categoryY = "category";
        series.columns.template.propertyFields.fill = "color"; // get color from data
        series.columns.template.propertyFields.stroke = "color";
        series.columns.template.strokeOpacity = 0;

        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.radius = 10;
        bullet.circle.strokeOpacity = 0;
        bullet.propertyFields.fill = "color";
        bullet.locationX = 1;
        // bullet.horizontalCenter = "middle";
        bullet.tooltipText = `[bold]{date}[/]`;
        bullet.states.create("hover")
        bullet.events.on("hit", function(ev) {
            handleSelect(ev.target.dataItem.dataContext)
        });

        let label = bullet.createChild(am4core.Label);
        label.text = "{count}";
        label.fill = new am4core.InterfaceColorSet().getFor("background");
        label.propertyFields.text = "label";
        label.strokeOpacity = 0;
        label.zIndex = 10;
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = 14;
        label.fontWeight = "600";
        label.dy = 1;


        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.align = "center"
        chart.scrollbarX.width = am4core.percent(85);

        let cursor = new am4plugins_timeline.CurveCursor();
        chart.cursor = cursor;
        cursor.xAxis = dateAxis;
        cursor.yAxis = categoryAxis;
        cursor.lineY.disabled = true;
        cursor.lineX.strokeDasharray = "1,4";
        cursor.lineX.strokeOpacity = 1;

        dateAxis.renderer.tooltipLocation2 = 0;
        categoryAxis.cursorTooltipEnabled = false;
    }

    function handleSelect(item) {
        // setSelectedItem(item.events)
        // searchImage(item.category ,item.datetime_format);
        setTableDataView(item.events)
        setModalStatus(true)
        setTableModal(true)
    }

    function handleDelete(value) {
        console.log("🚀 ~ file: Timeline.js ~ line 264 ~ handleDelete ~ handleDelete", value)
        setSubmitting(true)
        API.post(`${process.env.REACT_APP_API_URL}/tree/deleteSpecificEvent/`, {
            'id': value.id
        }, config).then( (res) => {
            setSubmitting(false)
            alert('Succesfully Deleted Event')
            handleModal('closeAll')
            setRefresh(true)
        })
        .catch(function (err) {
            setSubmitting(false)
            console.log(err)
        });
    }
    
    function handleUpdate(item) {
        setSubmitting(true)
        API.put(`${process.env.REACT_APP_API_URL}/tree/updateSpecificEvent/`, {
            "id" : item.id,
            "remark": item.remark,
            "type": item.type,
            "amount": item.amount
        },config).then( (res) => {
            setSubmitting(false)
            alert(res.data.message)
            handleModal('closeAll')
            setRefresh(true)
        })
        .catch(function (err) {
            setSubmitting(false)
            console.log(234, err)
        });
    }

    function handleAddEdit(value) {
        setSelectedItem( prev => ({
            ...prev,
            ...value
        }))
        handleModal('openAddEdit')
        searchImage(value.category ,value.datetime);
    }

    function handleView(value) {
        searchImage(value.category, value.datetime)
        setSelectedItem( prev => ({
            ...prev,
            ...value
        }))
        handleModal('openView')

    }

    function handleModal(option) {
        switch (option) {
            case 'openAddEdit':
                setModalStatus(true)
                setTableModal(false)
                setAddEditModal(true)
                setViewModal(false)
                break;
            case 'openTable':
                setModalStatus(true)
                setTableModal(true)
                setAddEditModal(false)
                setViewModal(false)
                break;
            case 'openView':
                setModalStatus(true)
                setTableModal(false)
                setAddEditModal(false)
                setViewModal(true)
                break;
            case 'backToTable':
                setModalStatus(true)
                setTableModal(true)
                setAddEditModal(false)
                setViewModal(false)
                break;
            case 'closeAll':
                setModalStatus(false)
                setTableModal(false)
                setAddEditModal(false)
                setViewModal(false)
                break;
            default:
                setModalStatus(false)
                setTableModal(false)
                setAddEditModal(false)
                setViewModal(false)
                break;
        }
    }
    
    if(loading){
        return (
            <span>Loading...</span>
        )
    } else {
            return (
                <>
                <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>

                
                <Modal
                    open={modalStatus}
                    close={() => { 
                        setModalStatus(false)
                    }}
                    aria-labelledby="event-modal-title"
                >
                    <div style={styles.modalContainer}>
                        <div style={{display:'flex', margin:'10px 10px 0 25px'}}>
                        <h3 style={{flex:11}}>List of events</h3>
                        <IconButton style={{flex:1, justifyContent:'flex-end'}} color="primary"  component="span" onClick={() => { handleModal('closeAll') }}>
                            <Close />
                        </IconButton>
                        </div>
                        
                        { tableModal && (
                            <TreeNodeTable 
                                tableData={tableDataView} 
                                remove={(value)=>{handleDelete(value)}}
                                addEdit={(value)=>{handleAddEdit(value)}}
                                view={(value)=>{handleView(value)}}
                                submitting={submitting}
                            />
                        )}
                        
                    </div>

                </Modal>

                { addEditModal && (
                    <NodeAddEditModal 
                    open={addEditModal}
                    close={() => { 
                        handleModal('backToTable')
                    }}
                    submit={(v)=>{handleUpdate(v)}}
                    data={selectedItem}
                    submitting={submitting} />
                )}

                { viewModal && (
                    <NodeViewModal 
                        open={viewModal}
                        close={() => { 
                            handleModal('backToTable')
                        }}
                        data={selectedItem}
                        loading={submitting} />
                )}
                
                </>
            );
        
    }
}

const styles = {
    modalContainer: {
        width: '80%',
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: '0 0 1px 0 rgb(0 0 0 / 31%), 0 4px 6px -2px rgb(0 0 0 / 25%)',
        margin: 'auto',
        marginTop: '200px',
        maxHeight: '600px',
        overflow:'scroll',
    },
    modalSubmitBtn: {
        textAlign:'right'
    },
}

  
const mapStateToProps = state => ({
    tree: state.tree.tree
});

export default connect(mapStateToProps) (Timeline);
