import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  IconButton
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import AddEditModal from './components/AddEditModal';
import axios from 'axios';
import API from '../../helpers/api'

let operation_type = [
    'pesticide', 'fungicide', 'pest', 'disease', 'fertilizer'
]
// let optionList = [
//     {
//         "id": 3,
//         "name": "Cephaleuros virescens"
//     },
//     {
//         "id": 4,
//         "name": "Trentepohlia aurea"
//     }
// ]

export const OperationSetting = (props) => {
    let { type } = useParams();
    let navigate = useNavigate();
    const [addEditStatus, setAddEditStatus] = useState(false)
    const [addEditData, setAddEditData] = useState({ 'title': 'Add', 'value': {} })
    const [optionList, setOptionList] = useState([])
    let config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token 535261045567516f2108c7b8029b3d96f95ceddc`,
        }
    };

    useEffect(() => {
        getTypeList()
    }, [type])

    function getTypeList() {
        setOptionList([])
        API.get(`${process.env.REACT_APP_API_URL}/${type}/getNameListV2/`, config)
        .then(function (res) {
            setOptionList(res.data)
        })
        .catch(function (err) {
        });
    }

    function handleSubmit(input) {
        if (addEditData.title == 'Add') {
            API.post(`${process.env.REACT_APP_API_URL}/${type}/saveNameList/`, {
                [type]: [
                    {"name": input}
                ],
            }, config)
            .then(function (res) {
                setAddEditStatus(false)
                getTypeList()
                alert('Successfully Added')
            })
            .catch(function (err) {
                alert(err.response)
            });
        } else if (addEditData.title == 'Edit') {
            API.put(`${process.env.REACT_APP_API_URL}/${type}/updateNameListV2/`, {
                "id": addEditData.value.id,
                "name": input
            }, config)
            .then(function (res) {
                setAddEditStatus(false)
                getTypeList()
                alert('Successfully Updated')
            })
            .catch(function (err) {
                alert(err.response)
            });
        }
        
    }

    function handleDelete(item) {
        API.post(`${process.env.REACT_APP_API_URL}/${type}/deleteNameListV2/`, {
            "id": item.id,
        }, config)
        .then(function (res) {
            getTypeList()
            alert('Successfully Deleted')
        })
        .catch(function (err) {
            alert(err.response)
        });
    }
    
    return (
        <div style={{padding: 20}}>
            <div className="body">
                <div className="buttons-container">
                    {operation_type.map( item => {
                        return (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    navigate('/app/operation-settings/' + item)
                                }}
                                style={{margin: 20,}}
                            >
                                {item}
                            </Button>
                        )
                    })}

                </div>
                
                <div className="toolbar-container float-right"> 
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setAddEditStatus(true)
                            setAddEditData({
                                'title': 'Add',
                                'value': ''
                            })
                        }}
                        style={{margin: 20}}
                    >
                        Add New Type
                    </Button>
                </div>
                <div className="table">
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="right">Name</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {optionList.length > 0 ? 
                            (
                                optionList.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell component="th" scope="row">{item.id}</TableCell>
                                    <TableCell align="right">{item.name}</TableCell>
                                    <TableCell align="right" padding="none">
                                        <IconButton aria-label="update" onClick={()=> {
                                            setAddEditStatus(true)
                                            setAddEditData({
                                                'title': 'Edit',
                                                'value': item
                                            })
                                        }}>
                                            <CreateIcon />
                                        </IconButton>
                                        <IconButton aria-label="delete" onClick={()=>{
                                            if (window.confirm('Are you sure you want to delete farm?')) {
                                                handleDelete(item)
                                            }
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                            ) : (
                                <p>No Data</p>
                            )
                        }
                            </TableBody>
                        </Table>
                        </TableContainer>

                </div>

            </div>
                                
            <AddEditModal 
                openModal={addEditStatus} 
                closeModal={() => {setAddEditStatus(false)}}
                onSubmit={(input) => {handleSubmit(input)} }
                data={addEditData}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(OperationSetting)
