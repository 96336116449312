import React from 'react'
import { connect } from 'react-redux'
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import {Backdrop, CircularProgress} from '@material-ui/core/';

const classes = {
    modalContainer: {
        width: '80%',
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: '0 0 1px 0 rgb(0 0 0 / 31%), 0 4px 6px -2px rgb(0 0 0 / 25%)',
        padding: '16px 32px 24px',
        margin: 'auto',
        marginTop: '200px',
    },
    modalSubmitBtn: {
        textAlign:'right'
    },
    backdrop: {
        zIndex: 10000,
        color: '#fff',
    },
}

export const NodeViewModal = ({open, close, data, loading}) => {

    return (
        <>
        <Backdrop style={classes.backdrop} open={loading}>
            <CircularProgress color="primary" />
        </Backdrop>
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="node-view-modal-title"
        >
            <div style={classes.modalContainer}>
                    <GridList cellHeight={160} cols={3} style={data.images.length > 0 ? {width: 'auto', height: 350,} : {}}>
                    {data.images.length > 0 && (
                        data.images.map( (img) => {
                            return (
                            <Grid item xs={3} style={{padding: '0 10px'}} onClick={()=>{ return window.open(img, '_blank', 'noopener,noreferrer') }}>
                                <img src={img}  alt="image" style={{width: '200px', height: 'auto'}}/>
                            </Grid>
                            )
                        })
                    )}
                    </GridList>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <label><b>Serial:</b></label>
                        <p>{data.serial}</p>
                    </Grid>
                    <Grid item xs={4}>
                        <label><b>Category:</b></label>
                        <p>{data.category}</p>
                    </Grid>
                    <Grid item xs={4}>
                        <label><b>Type:</b></label>
                        <p>{data.type}</p>
                    </Grid>
                    { data.category == 'disease' || data.category == 'pest'  ? (
                        null
                    ) : (
                        <Grid item xs={4}>
                            <label><b>Amount:</b></label>
                            <p>{data.amount}</p>
                        </Grid>
                    )}

                    <Grid item xs={4}>
                        <label><b>Datetime:</b></label>
                        <p>{data.datetime}</p>
                    </Grid>
                    <Grid item xs={4}>
                        <label><b>Submitted By:</b></label>
                        <p>{data.user}</p>
                    </Grid>
                    <Grid item xs={4}>
                        <label><b>Remark:</b></label>
                        <p>{data.remark}</p>
                    </Grid>


                </Grid>

                <div style={classes.modalSubmitBtn}>
                    <Button onClick={close} variant="contained">
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
        </>
    )
}

export default NodeViewModal
