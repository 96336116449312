import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {Add as AddIcon} from '@material-ui/icons';
import {InputLabel,Select,MenuItem, Backdrop, CircularProgress} from '@material-ui/core/';
import API from '../../../helpers/api';

const styles = {
    modalContainer: {
        width: '80%',
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: '0 0 1px 0 rgb(0 0 0 / 31%), 0 4px 6px -2px rgb(0 0 0 / 25%)',
        padding: '16px 32px 24px',
        margin: 'auto',
        marginTop: '200px',
        maxHeight: '600px',
        overflow:'scroll',
    },
    modalSubmitBtn: {
        textAlign:'right'
    },
    imgContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        position: 'relative',
        width: 'auto', 
        height: 350, 
        margin: '16px 0'
    },
    deletePlaceholder: {
        position:'absolute', 
        top:0, 
        right:0,
        background:'grey',
        borderRadius: '50%',
        height: '25px',
        width: '25px',
        textAlign: 'center',
    },
    imgPlaceHolder: {
        margin: '10px 15px',
        display:'block',
        maxWidth: '-webkit-fill-available',
        height: 'auto'
    },
    addImageField: {
        border: '1px dashed grey',
        borderRadius:'10px',
        minHeight: '150px',
        marginBottom: '15px',

        display: 'flex',
        flexFlow: 'row wrap',
        position: 'relative',
        width: 'auto', 
        margin: '16px 0'
    },
    backdrop: {
        zIndex: 10000,
        color: '#fff',
    },
}


export const NodeAddModal = ({open, close, tree, refresh}) => {
    const [category, setCategory] = useState('pest')
    let defaultData = {
        user:'',
        datetime:formatDate(new Date()),
        type:'',
        remark: '',
        category: category,    
        amount: '',
        images_blob: [],
        images_object: []
    }
    const [inputs, setInputs] = useState(defaultData)
    const [options, setOptions] = useState([])
    const [submitting, setSubmitting] = useState(false)
    let tree_id = tree[0].uuid;
    let tree_serial = tree[0].serial;
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };


    useEffect(() => {
        getOptions()
    }, [category])

    function handleClose(){
        setInputs(defaultData)
        close()
    }
    
    function handleChange (type, e) {
        setInputs(prev=>({
            ...prev,
            [type]: e.target.value
        }))
        if (type=='category') {
            for (let i in inputs.images_object) {
                URL.revokeObjectURL(inputs.images_object[i])
            }
            setCategory(e.target.value)
        }
    }

    
    const handleChangeImage = e => {
        setInputs((prev) => {
            let images_blob = prev.images_blob.push(URL.createObjectURL(e.target.files[0]))
            let images_object = prev.images_object.push(e.target.files[0])

            return {
                ...prev,
            }
        })
    }


    function getOptions() {
        setSubmitting(true)
        setInputs(defaultData)

        API.get(`${process.env.REACT_APP_API_URL}/${category}/getNameList/`, config)
        .then(res=>{
            setSubmitting(false)
            setOptions(res.data.name)
        }).catch(err=>{
            setSubmitting(false)
            close()
            alert(err)
        })
    }

    

    function handleSubmit() {
        let to_validate_inputs = ['user', 'type', 'category', 'datetime', 'amount']
        for (let i in inputs) {
            if (to_validate_inputs.includes(i) && inputs[i] == '') {
                return alert('Please fill up required inputs.')
            }
        }


        let counter = 1;
        let image_name = inputs.category

        let images_to_submit = {}
        for (let i in inputs.images_object) {
            if (counter < 10) {
                images_to_submit[`${image_name}0${counter}`] = inputs.images_object[i]
            } else {
                images_to_submit[`${image_name}${counter}`] = inputs.images_object[i]
            }
            counter ++;
        }

        setInputs(()=>({
            ...inputs,
            images_to_submit
        }))

        console.log({
            ...inputs,
            images_to_submit
        })

        submitData({
            ...inputs,
            images_to_submit
        })
    }

    function submitData(item) {

        setSubmitting(true)
        let datetime = new Date(item.datetime)
        let iso_datetime = datetime.toISOString() // 2021-07-20T00:00:00.000Z
        let image_date_name = formatDateTime(datetime) // 2021-07-20T00:00:00

        let serialize_obj = {
            "serial": tree_serial,  
            "category": item.category,    
            "datetime": iso_datetime,    
            "user": item.user,    
            "remark": item.remark,    
            "amount": item.amount,    
            "type": item.type,    
            "uuid": tree_id
        }
        let counter = 0;

        let bodyFormData = new FormData();
        bodyFormData.append('event', JSON.stringify([serialize_obj]));
        for (let i in item.images_to_submit) {
            let file = new File([item.images_to_submit[i]], image_date_name + "_" + counter, {type: "image/jpg", lastModified: Date.now()});
            bodyFormData.append(i, file);
            counter ++
        }

        API.post(`${process.env.REACT_APP_API_URL}/tree/uploadEventImage/${tree_serial}/ `, bodyFormData,config).then( (res) => {
            setSubmitting(false)
            alert(res.data.message)

            // Remove image in local storage
            for (let i in item.images_to_submit) {
                URL.revokeObjectURL(item.images_to_submit[i])
            }
            setInputs(defaultData)

            refresh()
            close()
        })
        .catch(function (err) {
            setSubmitting(false)
            alert(err)
        });

    }

    const deleteImg = (index) => {
        setInputs(prev=>{
            let updated_blob = prev.images_blob.filter((val,key) => key !== index)
            let updated_image_object = prev.images_object.filter((val,key) => key !== index)

            return {
                ...prev,
                images_blob: updated_blob,
                images_object: updated_image_object
            }
        })
    }

    return (
        <>
        <Backdrop style={styles.backdrop} open={submitting}>
            <CircularProgress color="primary" />
        </Backdrop>
        
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="node-add-edit-modal-title"
        >
            <div style={styles.modalContainer}>

                {(inputs.category=='pest' || inputs.category=='disease') && (
                    <div >
                        <GridList cellHeight={160} cols={3} style={(inputs.images_blob && inputs.images_blob.length > 0) ? styles.addImageField : {}}>
                                {(inputs.images_blob && inputs.images_blob.length > 0) && (
                                    inputs.images_blob.map( (img, key) => {
                                        return (
                                            <Grid item xs={3} style={{position:'relative', height:'auto'}} >
                                                <span style={styles.deletePlaceholder} onClick={()=>{deleteImg(key)}}>x</span>
                                                <img src={img}  alt="image" style={styles.imgPlaceHolder} onClick={()=>{ return window.open(img, '_blank', 'noopener,noreferrer') }}/>
                                            </Grid>
                                        )
                                    })
                                )}
                            
                        </GridList>
                        <div style={styles.modalSubmitBtn}>
                            <Button variant="contained" color="primary" component="label" 
                                startIcon={<AddIcon />}>
                                Add Image
                                <input
                                    name="imgEvents"
                                    type="file"
                                    accept="image/*"
                                    hidden
                                    onChange={handleChangeImage}
                                />
                            </Button>
                        </div>
                    </div>
                )}
                

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <InputLabel id="category-label" shrink required>Category</InputLabel>
                        <Select
                            labelId="category-label"
                            id="category"
                            value={inputs.category}
                            onChange={e =>handleChange('category', e)}
                            style={{width: '100%'}}
                            value={inputs.category}
                        >
                            <MenuItem key="pest" value="pest" >Pest</MenuItem>
                            <MenuItem key="pesticide" value="pesticide">Pesticide</MenuItem>
                            <MenuItem key="fungicide" value="fungicide">Fungicide</MenuItem>
                            <MenuItem key="disease" value="disease">Disease</MenuItem>
                            <MenuItem key="fertilizer" value="fertilizer">Fertilizer</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel id="category-label" shrink required>Type</InputLabel>
                        <Select
                            labelId="category-label"
                            id="category"
                            value={inputs.type}
                            onChange={e =>handleChange('type', e)}
                            style={{width: '100%'}}
                            displayEmpty
                        >
                            {options.map(option => {
                                return (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="User"
                            type="text"
                            fullWidth
                            value={inputs.user}
                            required
                            onChange={(e)=>handleChange('user', e)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Amount"
                            type="number"
                            fullWidth
                            value={inputs.amount}
                            required
                            onChange={(e)=>handleChange('amount', e)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="date"
                            label="Datetime"
                            type="date"
                            defaultValue={inputs.datetime}
                            style={{width: '100%'}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required
                            onChange={(e)=>handleChange('datetime', e)}
                        />
                    </Grid>
                </Grid>

                
                <Grid style={{marginTop: '10px'}}>
                        <Grid item xs={12}>
                            <label style={{fontSize:'12px'}}>Remark</label>
                            <TextareaAutosize 
                                label="Remark"
                                aria-label="minimum height" minRows={3} 
                                value={inputs.remark}
                                placeholder="Remark" 
                                style={{width:'100%', minHeight: '60px'}}
                                onChange={(e)=>handleChange('remark', e)}/>
                        </Grid>
                    </Grid>
                

                <div style={styles.modalSubmitBtn}>
                    <Button onClick={handleClose} disabled={submitting}>
                        Close
                    </Button>
                    <Button onClick={()=>{handleSubmit()}} variant="contained" color="primary">
                        Submit
                    </Button>
                </div>
            </div>
        </Modal>
        </>
    )
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
function formatDateTime(date) {
    let year = date.getFullYear(),
        month = date.getMonth() + 1, // months are zero indexed
        day = date.getDate(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds(),
        hourFormatted = hour,
        minuteFormatted = minute < 10 ? "0" + minute : minute,
        morning = hour < 12 ? "am" : "pm";

        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        if (hourFormatted < 10) {
            hourFormatted = "0" + hourFormatted;
        }
        if (second < 10) {
            second = "0" + second;
        }
    return year + "-" + month + "-" + day + "T" + hourFormatted + ":" + minuteFormatted + ":" + second;
}
  
const mapStateToProps = state => ({
    tree: state.tree.tree
});

export default connect(mapStateToProps) (NodeAddModal)
