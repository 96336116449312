import {React, useEffect, useState, setState} from 'react';
import { Link, Redirect, Navigate, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import Table from '../views/Table'
import Services from '../helpers/Services';
import Amplify, { Auth, Storage } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {DeleteTree} from '../actions/tree';
import CircularProgress from '@material-ui/core/CircularProgress';
import TabPanel from './TabPanel';
import API from '../helpers/api'


import TreeNodeTable from './Timeline/TreeNodeTable/TreeNodeTable'
import NodeViewModal from './Timeline/components/NodeViewModal'
import NodeAddEditModal from './Timeline/components/NodeAddEditModal'
import {formatDateTime} from '../helpers/helpers'
import NodeAddModal from './Timeline/components/NodeAddModal';

Amplify.configure({
  Auth: {
      identityPoolId: 'ap-southeast-1:306b09dd-a9be-40b4-b8eb-b14ffcb5d48b', //REQUIRED - Amazon Cognito Identity Pool ID
      region: 'ap-southeast-1', // REQUIRED - Amazon Cognito Region
  },
  Storage: {
      AWSS3: {
          bucket: `${process.env.REACT_APP_AWS_URL}`, //REQUIRED -  Amazon S3 bucket name
          region: 'ap-southeast-1', //OPTIONAL -  Amazon service region
      }
  }
});

const useStyles  = imgWidth => makeStyles((theme)=>({
  root: {
    width: '95%',
    margin: 'auto',
  },
  fle:{
    display: 'flex',
    width: '60%',
    margin: 'auto'
  },
	media: {
		'& > img' : {
			flex: 1,
			width: '100%',
			height: '100%',
			resizeMode: 'contain',
		},
		width: imgWidth,
		height: '400px',
	},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const defaultData = {
    images: []
}


const TreeView = ({ treeLoaded, tree, DeleteTree }) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [imgWidth, setImgWidth] = useState('100%');
    const classes = useStyles(imgWidth)();
    const [tableData, setTableData] = useState([])
    const [viewModal, setViewModal] = useState(false)
    const [addEditModal, setAddEditModal] = useState(false)
    const [selected, setSelected] = useState(defaultData)
    const [submitting, setSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [refresh, setRefresh] = useState(false)
    const [category, setCategory] = useState('all')
    const [addModalStatus, setAddModalStatus] = useState(false)
    var tree_id;

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const editTree = ()=>{
      navigate('/app/trees/edit');
    };

    const handleDelete = ()=>{
      setDeleting(true);
      DeleteTree(tree[0].uuid).then(()=>{
        setDeleting(false);
        navigate('/app/trees');
      });
    };

    useEffect(() => {
        if (refresh) {
            getData()
        }
    }, [refresh])


    useEffect(() => {
        getData()
    }, [category])


	useEffect(() => {
		if (tree) {
            setRefresh(true)
            tree_id = tree[0].uuid;
			// IMAGE LANDSCAPE/PORTRAIT HANDLING
			let img = new Image();
			img.src = tree[0].url;
			img.onload = () => {
				if (img.naturalWidth > img.naturalHeight){
					// LANDSCAPE
					setImgWidth('100%')
				} else if (img.naturalWidth  < img.naturalHeight){
					// PORTRAIT
					setImgWidth('50%')
				} else {
					// SQUARE
					setImgWidth('100%')
				}
			};
		}
	}, [tree])

    function viewData(item) {
        setSelected(prev=> {
            item['images'] = []
            return item
        })
        setViewModal(true)
        searchImage(item)
    }

    function searchImage(item) {
        setLoading(true)
        let tree_serial = tree[0].serial;
        let format_datetime = formatDateTime(new Date(item.datetime).toString())

        let image_arr = [];
        API.get(`${process.env.REACT_APP_API_URL}/tree/downloadEventImage/${tree_serial}/${item.category}/${format_datetime}`)
        .then(res => {
            setLoading(false)
            if (res.data.url.length > 0) {
                for (let i in res.data.url) {
                    console.log(res.data.url[i] )
                    image_arr.push(res.data.url[i])
                }
                setSelected( prev => ({
                    ...prev,
                    'images': image_arr
                }))
            } else {
                setSelected( prev => ({
                    ...prev,
                    'images': []
                }))
            }
        }).catch(err => {
            setLoading(false)
            console.log(err)
        })
    }

    function removeData(item) {
        console.log("🚀 ~ file: TreeView.js ~ line 174 ~ deleteData ~ deleteData", item)
        setSubmitting(true)
        API.post(`${process.env.REACT_APP_API_URL}/tree/deleteSpecificEvent/`, {
            'id': item.id
        }, config).then( (res) => {
            setSubmitting(false)
            alert('Succesfully Deleted Event')
            if (tree) {
                setRefresh(true)
            }
        })
        .catch(function (err) {
            setSubmitting(false)
            console.log(err)
        });
        
    }

    function getData() {
        if (localStorage.getItem('access') && tree) {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `JWT ${localStorage.getItem('access')}`,
                        'Accept': 'application/json'
                    }
                };

            setLoading(true)
            tree_id = tree[0].uuid
            API.get(`${process.env.REACT_APP_API_URL}/tree/getEventList/${category}/6/${tree_id}/`, config).then( (res) => {
                setRefresh(false)
                setLoading(false)
                if (res.data.event.length > 0) {
                    setTableData(res.data.event)
                } else {
                    setTableData([])
                }
            })
            .catch(function (err) {
                setRefresh(false)
                setLoading(false)
                console.log(err)
                alert('Something wrong!')
            });
        } else {
            console.log('Unauthorized. Please log in.')
        }
    }

    function handleUpdate(item) {
        setSubmitting(true)
        API.put(`${process.env.REACT_APP_API_URL}/tree/updateSpecificEvent/`, {
            "id" : item.id,
            "remark": item.remark,
            "type": item.type,
            "amount": item.amount
        },config).then( (res) => {
            setSubmitting(false)
            alert(res.data.message)
            setAddEditModal(false)
            if (tree){
                setRefresh(true)
            }            
        })
        .catch(function (err) {
            setSubmitting(false)
            console.log(234, err)
        });
    }


    if(!treeLoaded)
        return (
            <Card className={classes.root}>
            <CardActionArea>
              <CardContent>
                    Loading...
              </CardContent>
            </CardActionArea>
          </Card>
        )
    return (
		<>
        <Backdrop className={classes.backdrop} open={loading} >
            <CircularProgress color="primary" />
        </Backdrop>
        <Card className={classes.root}>
			<CardActionArea className={classes.fle}>
				<CardMedia
				className={classes.media}
				image={tree[0].url}
				title="Image"
				component="img"
				/>
				<CardContent>
				<Typography gutterBottom variant="h5" component="h2">
					{tree[0].serial}, {tree[0].breed}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					Age: {tree[0].age}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					Height: {tree[0].height}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					Diameter: {tree[0].diameter}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					Horizontal Line: {tree[0].horizontal_line}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					Latitude: {tree[0].latitude}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					Longitude: {tree[0].longitude}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					Remark: {tree[0].remark}
				</Typography>
				</CardContent>
			</CardActionArea>
          <CardActions>
            <Button size="small" color="primary" onClick={editTree}>
              Edit
            </Button>
            {/* <Button size="small" color="secondary" onClick={handleOpen}>
              Delete
            </Button> */}
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <h2 id="transition-modal-title">Press confirm to delete record</h2>
                  <Button size="small" color="secondary" onClick={handleDelete}>
                  {deleting ? (<CircularProgress color="inherit" />):'Confirm to delete'}
                  </Button>
                </div>
              </Fade>
            </Modal>
          </CardActions>

          <TabPanel category={category} setCategory={setCategory} refresh={refresh} setRefresh={setRefresh} tableData={tableData}/>

        </Card>

        <div style={{width: '97%', textAlign: 'right', margin: '20px 0'}}>
            <Button variant="contained" color="primary" onClick={()=>{
                setAddModalStatus(true)
            }}>
                Add Event
            </Button>
        </div>

        <NodeAddModal open={addModalStatus} close={()=>{
            setAddModalStatus(false)
        }}
        refresh={()=>{
            setRefresh(true)
        }}/>


        <TreeNodeTable tableData={tableData} view={ (data) => {viewData(data)}} remove={(data)=> {removeData(data)}} addEdit={(data)=>{
            setAddEditModal(true)
            setSelected(prev=> {
                data['images'] = []
                return data
            })
            }}
            submitting={submitting}/>

        <NodeViewModal open={viewModal} 
            close={() => {
                setViewModal(false)
                setSelected(defaultData)
            }} 
            data={selected}
            loading={loading}/>


        <NodeAddEditModal open={addEditModal} 
            close={() => {
                setAddEditModal(false)
                setSelected(defaultData)
            }} 
            data={selected}
            submit={(v)=>{
                handleUpdate(v)
            }}
            submitting={submitting}/>
    </>

        
        
      );

      
    
    
};

const mapStateToProps = state => ({
    treeLoaded: state.tree.treeLoaded,
    treeDeleted: state.tree.treeDeleted,
    tree: state.tree.tree
});

export default connect(mapStateToProps, {DeleteTree})(TreeView);
