import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Timeline from './Timeline/Timeline';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({setCategory, refresh, setRefresh, tableData}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    function handleTabChange(cat) {
        setCategory(cat)
    }

  return (
    <div className={classes.root}>
		<AppBar position="static">
			<Tabs 
			value={value} 
			onChange={handleChange} 
			variant="scrollable"
			scrollButtons="auto"
			aria-label="scrollable auto tabs example"
			>
			<Tab label="Dashboard" {...a11yProps(0)} onClick={()=>{handleTabChange('all')}}/>
			<Tab label="Fruits" {...a11yProps(1)} />
			<Tab label="Pests" {...a11yProps(2)} onClick={()=>{handleTabChange('pest')}}/>
			<Tab label="Fertilizers" {...a11yProps(3)} onClick={()=>{handleTabChange('fertilizer')}}/>
			<Tab label="Disease" {...a11yProps(4)} onClick={()=>{handleTabChange('disease')}}/>
			<Tab label="Pesticide" {...a11yProps(5)} onClick={()=>{handleTabChange('pesticide')}}/>
			<Tab label="Fungicide" {...a11yProps(6)} onClick={()=>{handleTabChange('fungicide')}}/>
			</Tabs>
		</AppBar>
		<TabPanel value={value} index={0} >
			<Timeline refresh={refresh} setRefresh={setRefresh} category='all' tableData={tableData} />
		</TabPanel>
		<TabPanel value={value} index={1}>
			Fruits
		</TabPanel>
		<TabPanel value={value} index={2}>
            <Timeline refresh={refresh} setRefresh={setRefresh} category='pest' tableData={tableData} />
		</TabPanel>
		<TabPanel value={value} index={3}>
            <Timeline refresh={refresh} setRefresh={setRefresh} category='fertilizer' tableData={tableData} />
		</TabPanel>
		<TabPanel value={value} index={4}>
            <Timeline refresh={refresh} setRefresh={setRefresh} category='disease' tableData={tableData} />
		</TabPanel>
		<TabPanel value={value} index={5}>
            <Timeline refresh={refresh} setRefresh={setRefresh} category='pesticide' tableData={tableData} />
		</TabPanel>
		<TabPanel value={value} index={6}>
            <Timeline refresh={refresh} setRefresh={setRefresh} category='fungicide' tableData={tableData} />
		</TabPanel>
    </div>
  );
}