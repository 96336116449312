import React, {useState} from 'react'
import { connect } from 'react-redux'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
    Delete as DeleteIcon,
    Create as CreateIcon,
    Visibility as VisibilityIcon,
} from '@material-ui/icons';
import {
  IconButton
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';

export const TreeNodeTable = ({ tableData, view, remove, addEdit, submitting}) => {
    // PAGINATION
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <div style={{
            width: '95%',
            margin: '30px auto',}}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="right">Serial</TableCell>
                        <TableCell align="right">Category</TableCell>
                        <TableCell align="right">Type</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Datetime</TableCell>
                        <TableCell align="right">Submitted By</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.length > 0 ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => {
                            return (
                                <TableRow >
                                    <TableCell component="th" scope="row">{item.id}</TableCell>
                                    <TableCell align="right">{item.serial}</TableCell>
                                    <TableCell align="right">{item.category}</TableCell>
                                    <TableCell align="right">{item.type}</TableCell>
                                    <TableCell align="right">{item.amount}</TableCell>
                                    <TableCell align="right">{item.datetime}</TableCell>
                                    <TableCell align="right">{item.user}</TableCell>
                                    <TableCell align="right">
                                        <IconButton aria-label="view" color="primary" onClick={()=>(view(item))}>
                                            <VisibilityIcon />
                                        </IconButton>
                                        <IconButton aria-label="update" color="primary" onClick={()=>(addEdit(item))}>
                                            <CreateIcon />
                                        </IconButton>
                                            <IconButton aria-label="delete" color="primary" onClick={()=> {
                                                if (window.confirm('Are you sure you want to delete node?')) {
                                                    remove(item)
                                                }
                                            }}>{submitting ? (
                                                <CircularProgress />
                                            ) : (
                                                <DeleteIcon />
                                            )}
                                            </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        }) : (
                            <p>No Data Recorded</p>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeNodeTable)