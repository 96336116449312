import axios from 'axios';
import {
    TREE_LOADED_FAIL,
    TREE_LOADED_SUCCESS,
    CLEAR_TREE_VIEW,
    TREE_UPLOAD_SUCCESS,
    TREE_UPLOAD_FAIL,
    TREE_UPDATE_SUCCESS,
    TREE_UPDATE_FAIL,
    TREE_DELETE_SUCCESS,
    TREE_DELETE_FAIL
} from './types';
import API from '../helpers/api'
import Amplify, { Auth, Storage } from 'aws-amplify';



Amplify.configure({
    Auth: {
        identityPoolId: 'ap-southeast-1:306b09dd-a9be-40b4-b8eb-b14ffcb5d48b', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'ap-southeast-1', // REQUIRED - Amazon Cognito Region
    },
    Storage: {
        AWSS3: {
            bucket: `${process.env.REACT_APP_AWS_URL}`, //REQUIRED -  Amazon S3 bucket name
            region: 'ap-southeast-1', //OPTIONAL -  Amazon service region
        }
    }
});

export const loadTree = (serial, uuid) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    try {
        const res = await API.get(`${process.env.REACT_APP_API_URL}/tree/getRecordV2/${uuid}/`, config);
        res.data.tree[0].url = res.data.url[0];

        // try{
        //     const signedURL = await Storage.get(`${serial}/trees/1.jpg`);
        //     // console.log('loadTree', signedURL);
        //     // const data = await Storage.get(`${serial}/trees/1.jpg`, { download: true });
        //             // data.Body is a Blob
        //             // data.Body.text().then(string => { 
        //             // // handle the String data return String 
        //             // })

        //     res.data.tree[0].url = signedURL;
        //     res.data.tree[0].blob = signedURL.Body;
        // }catch (err){
        //     console.log('loadTree', err)
        // }
        


        dispatch({
            type: TREE_LOADED_SUCCESS,
            payload: res.data
        });

        //dispatch(load_user());
    } catch (err) {
        dispatch({
            type: TREE_LOADED_FAIL
        });
    }
};

export const treeInfo = (uuid) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    try {
        const res = await API.get(`${process.env.REACT_APP_API_URL}/tree/getRecord/${uuid}/`, config);
        delete axios.defaults.headers.common["Authorization"];
        const signedURL = await Storage.get(`${res.data.tree[0].serial}/trees/1.jpg`);
        console.log('treeInfo', signedURL)

        res.data.tree[0].url = signedURL;

        dispatch({
            type: TREE_LOADED_SUCCESS,
            payload: res.data
        });

        //dispatch(load_user());
    } catch (err) {
        dispatch({
            type: TREE_LOADED_FAIL
        });
    }
};

 export const clearTreeView = () => dispatch => {
     dispatch({ type: CLEAR_TREE_VIEW });
 };


 export const UploadTree = (tree, treeImage) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': "multipart/form-data",
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };
    try {
        let serial = tree.tree[0].serial.toString() + '_01'

        // RECONSTRUCT FILEDATA
        let file = new File([treeImage], "1.jpg", {type: "image/jpg", lastModified: Date.now()});
        
        let bodyFormData = new FormData();
        bodyFormData.append('tree', JSON.stringify(tree.tree));
        bodyFormData.append(serial, file);

        API.post(`${process.env.REACT_APP_API_URL}/tree/saveRecordV2/`, bodyFormData, config)
        .then( res => {
            console.log('UploadTree',res)
            dispatch({
                type: TREE_UPLOAD_SUCCESS
            });
        }).catch( err => {
            console.log('UploadTree',err)
            dispatch({
                type: TREE_UPLOAD_FAIL
            });
        })

        // await Storage.put(tree.tree[0].serial+'/trees/1.jpg', treeImage, {
        //     contentType: 'image/jpg'
        // })
        // .then (result => console.log('UploadTree',result))
        // .catch(err => console.log('UploadTree', err));

        // //res.data.tree[0].url = signedURL;

        //dispatch(load_user());
    } catch (err) {
        dispatch({
            type: TREE_UPLOAD_FAIL
        });
    }
};


export const UpdateTree = (tree, treeImage) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    try {
        const res = await API.post(`${process.env.REACT_APP_API_URL}/tree/updateRecord/`, tree, config);
        
        delete axios.defaults.headers.common["Authorization"];
        await Storage.put(tree.tree[0].serial+'/trees/1.jpg', treeImage, {
            contentType: 'image/jpg'
        })
        .then (result => console.log('UpdateTree',result))
        .catch(err => console.log('UpdateTree',err));

        //res.data.tree[0].url = signedURL;
        dispatch({
            type: TREE_UPDATE_SUCCESS
        });

        //dispatch(load_user());
    } catch (err) {
        dispatch({
            type: TREE_UPDATE_FAIL
        });
    }
};




export const DeleteTree = (uuid) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    try {
        const res = await API.delete(`${process.env.REACT_APP_API_URL}/tree/deleteRecord/${uuid}`, config);

        dispatch({
            type: TREE_DELETE_SUCCESS
        });

        //dispatch(load_user());
    } catch (err) {
        dispatch({
            type: TREE_DELETE_FAIL
        });
    }
};

